import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axiosInstance from "../../api";
import { CarBrandBlacklistAdminDTO } from "./models/CarBrandBlacklistAdminDTO";

export function CarBrandBlacklist() {
    const [t] = useTranslation();
    const [blackList, setBlackList] = useState<CarBrandBlacklistAdminDTO>({
        blacklistedCarBrands: [],
        notBlacklistedCarBrands: [],
    });

    function getData() {
        axiosInstance
            .get<CarBrandBlacklistAdminDTO>("/car/brandblacklist")
            .then((response) => {
                setBlackList(response.data);
            });
    }

    function saveData() {
        toast.promise(
            axiosInstance.put<void>(
                "/car/brandblacklist",
                blackList.blacklistedCarBrands
            ),
            {
                pending: t("CAR_BRAND_BLACKLIST.SAVING"),
                success: t("CAR_BRAND_BLACKLIST.SAVED"),
                error: t("CAR_BRAND_BLACKLIST.NOT_SAVED"),
            }
        );
    }

    useEffect(() => {
        getData();
    }, []);

    function block(cb: string) {
        setBlackList({
            blacklistedCarBrands: blackList.blacklistedCarBrands.concat([cb]).sort(),
            notBlacklistedCarBrands: blackList.notBlacklistedCarBrands.filter(
                (b) => b !== cb
            ),
        });
    }

    function unblock(cb: string) {
        setBlackList({
            blacklistedCarBrands: blackList.blacklistedCarBrands.filter(
                (b) => b !== cb
            ),
            notBlacklistedCarBrands: blackList.notBlacklistedCarBrands.concat([cb]).sort(),
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <h3>{t("CAR_BRAND_BLACKLIST.TITLE")}</h3>
                </div>
                <div className="col-6 text-end">
                    <button className="btn btn-primary" onClick={() => saveData()}>
                        {t("CAR_BRAND_BLACKLIST.SAVE")}
                    </button>
                </div>
                <div className="col-6">
                    <h2>{t("CAR_BRAND_BLACKLIST.AVAILABLE")}</h2>
                    <ul className="list-group">
                        {blackList?.notBlacklistedCarBrands.map((cb) => (
                            <button
                                className="list-group-item list-group-item-action"
                                key={cb}
                                onClick={() => block(cb)}
                            >
                                {cb}
                            </button>
                        ))}
                    </ul>
                </div>
                <div className="col-6">
                    <h2>{t("CAR_BRAND_BLACKLIST.BLOCKED")}</h2>
                    <ul className="list-group">
                        {blackList?.blacklistedCarBrands.map((cb) => (
                            <button
                                className="list-group-item list-group-item-action"
                                key={cb}
                                onClick={() => unblock(cb)}
                            >
                                {cb}
                            </button>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}
