import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from '../../../api';
import {Button, Form} from 'react-bootstrap';
import {OfferSliderEntryDTO} from '../models/OfferSliderEntryDTO';
import moment from 'moment';
import * as yup from 'yup';
import Datetime from "react-datetime";
import {Formik} from 'formik';
import bsCustomFileInput from "bs-custom-file-input";
import styles from './OfferSliderDetails.module.scss';

function OfferSliderDetails() {
    const [t] = useTranslation();
    const navigate = useNavigate();
    let {id} = useParams<{ id: string }>();
    const create = id === 'create';
    const initialFormValues: OfferSliderEntryDTO = {
        id: 0,
        hoverText: '',
        link: '',
        validFrom: moment(),
        validTo: moment(),
        image: undefined
    }

    const validationSchema = (create: boolean) => {
        return yup.object().shape({
            image: create ? yup.mixed().required(t('VALIDATION.REQUIRED')) : yup.mixed().optional(),
            hoverText: yup.string()
                .max(200, t('VALIDATION.MAX_200')),
            link: yup.string(),
            validFrom: yup.date()
                .max(yup.ref('validTo'), t('VALIDATION.START_DATE_MAX_ERROR'))
                .required(t('VALIDATION.REQUIRED')),
            validTo: yup.date()
                .min(yup.ref('validFrom'), t('VALIDATION.END_DATE_MIN_ERROR'))
                .required(t('VALIDATION.REQUIRED'))
        });
    }

    const [loadedFormValues, setLoadedFormValues] = useState<OfferSliderEntryDTO | null>(null);

    useEffect(() => {
        bsCustomFileInput.init();
        if (!create) {
            axiosInstance.get<OfferSliderEntryDTO>('/offerslider/' + id).then(response => {
                setLoadedFormValues(response.data);
            });
        }
    }, [create, t, id]);

    function submitForm(data: OfferSliderEntryDTO) {
        let slider: OfferSliderEntryDTO = data;
        if (create) {
            createOfferSlider(slider);
        } else {
            slider.id = +id;
            slider.imageId = loadedFormValues?.imageId;
            editOfferSlider(slider);
        }
    }

    function createOfferSlider(slider: OfferSliderEntryDTO) {
        let data = new FormData();
        data.append('link', slider.link!);
        data.append('hoverText', slider.hoverText!);
        data.append('validFrom', moment(slider.validFrom).toISOString());
        data.append('validTo', moment(slider.validTo).toISOString());
        data.append('image', slider.image);
        axiosInstance.post('/offerslider', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(() => {
            navigate('/offer-sliders');
        });
    }

    function editOfferSlider(slider: OfferSliderEntryDTO) {
        axiosInstance.put('/offerslider/' + slider.id, slider).then(() => {
            navigate('/offer-sliders');
        });
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center pb-3">
                <h3>{t('OFFER_SLIDERS.' + (create ? 'CREATE' : 'EDIT_SLIDER'))}</h3>
                <Button variant="primary" href="/offer-sliders">{t('OFFER_SLIDERS.BACK')}</Button>
            </div>
            <Formik
                validationSchema={validationSchema(create)}
                onSubmit={(data) => submitForm(data)}
                initialValues={loadedFormValues || initialFormValues}
                enableReinitialize
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      dirty,
                      isValid,
                      setFieldValue,
                      setFieldTouched,
                      errors,
                  }) => {
                    return (
                      <div>
                        <Form
                          onSubmit={handleSubmit}
                          className="form"
                          noValidate
                        >
                          {create && (
                            <div>
                              <Form.Group className="pb-3">
                                <Form.Label>
                                  {t("OFFER_SLIDERS.IMAGE")}
                                </Form.Label>
                                <Form.Control
                                  name="image"
                                  id="imageControl"
                                  type="file"
                                  accept="image/*"
                                  data-browse={t("OFFER_SLIDERS.CHOOSE_FILE")}
                                  onChange={(event: any) => {
                                    setFieldTouched("image");
                                    setFieldValue(
                                      "image",
                                      event.target.files[0]
                                    );
                                  }}
                                  isInvalid={!!(touched.image && errors.image)}
                                />
                                  <Form.Control.Feedback>
                                      <>{errors.image}</>
                                  </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          )}
                          {loadedFormValues?.imageId && (
                            <div className={styles.imageContainer}>
                              <img
                                src={
                                  "/api/offerslider/" +
                                  loadedFormValues.imageId +
                                  "/image"
                                }
                                alt="img"
                              />
                            </div>
                          )}
                          <Form.Group className="pb-3" controlId="hoverTextControl">
                            <Form.Label>
                              {t("OFFER_SLIDERS.HOVER_TEXT")}
                            </Form.Label>
                            <Form.Control
                              name="hoverText"
                              type="text"
                              value={values.hoverText}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                !!(touched.hoverText && errors.hoverText)
                              }
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.hoverText}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="pb-3" controlId="linkControl">
                            <Form.Label>{t("OFFER_SLIDERS.LINK")}</Form.Label>
                            <Form.Control
                              name="link"
                              type="text"
                              value={values.link}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!(touched.link && errors.link)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.link}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="pb-3" controlId="validFromControl">
                            <Form.Label>
                              {t("OFFER_SLIDERS.VALID_FROM")}
                            </Form.Label>
                            <Datetime
                              value={moment(values.validFrom)}
                              onChange={(e) => {
                                setFieldValue("validFrom", e);
                                setFieldTouched("validFrom");
                              }}
                              inputProps={
                                touched.validFrom && errors.validFrom
                                  ? {
                                      required: true,
                                      name: "validFrom",
                                      className: "form-control is-invalid",
                                    }
                                  : {
                                      required: true,
                                      name: "validFrom",
                                      className: "form-control",
                                    }
                              }
                              timeFormat={false}
                              closeOnSelect={true}
                            />
                          </Form.Group>
                          <Form.Group className="pb-3" controlId="validToControl">
                            <Form.Label>
                              {t("OFFER_SLIDERS.VALID_TO")}
                            </Form.Label>
                            <Datetime
                              value={moment(values.validTo)}
                              onChange={(e) => {
                                setFieldValue("validTo", e);
                                setFieldTouched("validTo");
                              }}
                              inputProps={
                                touched.validTo && errors.validTo
                                  ? {
                                      required: true,
                                      name: "validTo",
                                      className: "form-control is-invalid",
                                    }
                                  : {
                                      required: true,
                                      name: "validTo",
                                      className: "form-control",
                                    }
                              }
                              timeFormat={false}
                              closeOnSelect={true}
                            />
                          </Form.Group>
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={!(dirty && isValid)}
                          >
                            {t("OFFER_SLIDERS.SAVE")}
                          </Button>
                        </Form>
                      </div>
                    );
                }}
            </Formik>
        </div>
    );
}

export default OfferSliderDetails;
