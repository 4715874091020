import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import {useTranslation} from 'react-i18next';
import { useNavigate } from "react-router-dom";
import {ShippingDTO} from './models/ShippingDTO';
import styles from  './ShippingCosts.module.scss';
import Button from 'react-bootstrap/Button';
import axiosInstance from '../../api';
import { NumericFormat } from 'react-number-format';

function ShippingCosts() {
    const [shippingCosts, setShippingCosts] = useState<ShippingDTO[]>([]);
    const [t] = useTranslation();
    const navigate = useNavigate();

    const goToDetailPage = (country: string | undefined) => {
        navigate('/shipping-costs/' + country)
    }
    const handleDelete = (country: string | undefined, event: any) => {
        event.stopPropagation();
        axiosInstance.delete('/shipping/' + country).then(response => {
            getShippingCosts();
        });
    }

    const getShippingCosts = () => {
        axiosInstance.get<ShippingDTO[]>('/shipping').then(response => {
            setShippingCosts(response.data);
        });
    }

    useEffect(() => {
        getShippingCosts();
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center pb-3">
                <h3>{t('NAVIGATION.SHIPPING_COSTS')}</h3>
                <Button variant="primary" href="/shipping-costs/create">{t('SHIPPING_COSTS.NEW')}</Button>
            </div>
            <Table striped hover>
                <thead>
                <tr>
                    <th>{t('SHIPPING_COSTS.COUNTRY')}</th>
                    <th className="text-center">{t('SHIPPING_COSTS.SHIPPING')}</th>
                    <th className="text-end">{t('SHIPPING_COSTS.COSTS')}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {shippingCosts.map((item: ShippingDTO) =>
                    <tr className={styles.click} key={item.id} onClick={() => goToDetailPage(item.country)}>
                        <td>{t('ENUMS.COUNTRIES.' + item.country)}</td>
                        <td className="text-center">
                            {t('SHIPPING_COSTS.' + (item.freeShipping ? 'NO' : 'YES'))}
                        </td>
                        <td className="text-end">
                            <NumericFormat value={item.costs}
                                          displayType={'text'}
                                          decimalSeparator={','}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          suffix={'€'} />
                        </td>
                        <td className="text-end">
                            <Button variant="primary" onClick={(e) => handleDelete(item.country, e)}>
                                {t('SHIPPING_COSTS.DELETE')}
                            </Button></td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
}

export default ShippingCosts;
