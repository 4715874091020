import React from 'react';
import {useTranslation} from "react-i18next";
import {saveAs} from 'file-saver';
import axiosInstance from '../../api';
import * as yup from "yup";
import {Button, Form} from "react-bootstrap";
import styles from "../VoucherManagement/VoucherDetails/VoucherDetails.module.scss";
import Datetime from "react-datetime";
import moment, {Moment} from "moment";
import {Formik} from "formik";

interface StatisticsTimeRange {
    from: Moment | string;
    to: Moment | string;
}

function Statistics() {
    const [t] = useTranslation();

    const validationSchema = yup.object().shape({
        from: yup.date()
            .max(yup.ref('to'), t('VALIDATION.START_DATE_MAX_ERROR'))
            .required(t('VALIDATION.REQUIRED')),
        to: yup.date()
            .max(moment())
            .min(yup.ref('from'), t('VALIDATION.END_DATE_MIN_ERROR'))
            .required(t('VALIDATION.REQUIRED')),
    });

    function submitForm(data: StatisticsTimeRange, resetForm: () => void) {
        axiosInstance.get('/statistics/producttypeorders/csv', {
            params: {
                from: moment(data.from).format('YYYY-MM-DD'),
                to: moment(data.to).format('YYYY-MM-DD')
            },
            responseType: 'blob'
        })
            .then(response => {
                let fileName = `Bestellstatistik_${moment(data.from).format('YYYY-MM-DD')}_${moment(data.to).format('YYYY-MM-DD')}`;
                saveAs(response.data, fileName);
                resetForm();
            });
    }

    return (
        <div>
            <Formik
                validationSchema={validationSchema}
                onSubmit={(data, {resetForm}) => submitForm(data, resetForm)}
                initialValues={{from: moment().subtract(1, 'month'), to: moment()}}
            >
                {({
                      handleSubmit,
                      values,
                      touched,
                      isValid,
                      setFieldValue,
                      setFieldTouched,
                      errors,
                  }) => {
                    return (
                        <div>
                            <div className="pb-3">
                                <h3>{t('STATISTICS.HEADING')}</h3>
                            </div>
                            <Form onSubmit={handleSubmit} className={styles.form} noValidate>
                                <Form.Group className="pb-3" controlId="fromControl">
                                    <Form.Label>{t('STATISTICS.FROM')}</Form.Label>
                                    <Datetime value={moment(values.from)}
                                              onChange={e => {
                                                  setFieldValue('from', e);
                                                  setFieldTouched('from');
                                              }}
                                              inputProps={(touched.from && errors.from) ?
                                                  {
                                                      required: true,
                                                      name: 'from',
                                                      className: 'form-control is-invalid'
                                                  } : {
                                                      required: true,
                                                      name: 'from',
                                                      className: 'form-control'
                                                  }
                                              }
                                              isValidDate={current => current.isBefore(moment()) && current.isBefore(values.to)}
                                              timeFormat={false}
                                              closeOnSelect={true}
                                              renderInput={(props) => {
                                                  return <input {...props} value={props.value}/>
                                              }}
                                    />
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="toControl">
                                    <Form.Label>{t('STATISTICS.TO')}</Form.Label>
                                    <Datetime value={moment(values.to)}
                                              onChange={e => {
                                                  setFieldValue('to', e);
                                                  setFieldTouched('to');
                                              }}
                                              inputProps={(touched.to && errors.to) ?
                                                  {
                                                      required: true,
                                                      name: 'to',
                                                      className: 'form-control is-invalid'
                                                  } : {
                                                      required: true,
                                                      name: 'to',
                                                      className: 'form-control'
                                                  }
                                              }
                                              isValidDate={current => current.isBefore(moment()) && current.isAfter(values.from)}
                                              timeFormat={false}
                                              closeOnSelect={true}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" disabled={!isValid}>
                                    {t('STATISTICS.DOWNLOAD_ORDER_STATISTICS')}
                                </Button>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )
}

export default Statistics;
