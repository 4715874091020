import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import axiosInstance from "../../../api";
import { PositionType, VoucherCategory, VoucherDTO, VoucherItemType, VoucherType } from "../models/VoucherDTO";
import styles from "./VoucherDetails.module.scss";

const voucherTypes = [VoucherType.DISCOUNT_VOUCHER, VoucherType.FIXED_PRICE_VOUCHER, VoucherType.PERCENTAGE_VOUCHER];
const itemTypes = [VoucherItemType.ARTICLE, VoucherItemType.MOUNTINGKIT, VoucherItemType.RIM, VoucherItemType.TPMS,
    VoucherItemType.TYRE, VoucherItemType.VOUCHER];
const positionTypes = Object.values(PositionType);
const voucherCategories = [VoucherCategory.CART, VoucherCategory.ITEM];

function VoucherDetails() {
    const [t] = useTranslation();
    const navigate = useNavigate();
    let { id } = useParams<{ id: string }>();
    const create = id === 'create';
    const initialFormValues: VoucherDTO = useMemo<VoucherDTO>(() => {
        return {
            code: "",
            name: "",
            type: VoucherType.DISCOUNT_VOUCHER,
            useItemType: false,
            itemType: null,
            positionType: null,
            validFrom: moment(),
            validTo: moment(),
            valueToBuy: 0,
            valueToPay: 0,
            useMinimumOrderPrice: false,
            minimumOrderPrice: 0,
            useMinimumOrderValue: false,
            minimumOrderValue: 0,
            voucherCategory: VoucherCategory.ITEM,
            priceReduction: 0,
            percentageReduction: 0,
        };
    }, []);

    const validationSchema = yup.object().shape({
        code: yup.string()
            .required(t('VALIDATION.REQUIRED')),
        name: yup.string()
            .required(t('VALIDATION.REQUIRED')),
        type: yup.string()
            .required(t('VALIDATION.REQUIRED')),
        itemType: yup.string().nullable().when(['type', 'useItemType', 'positionType'], {
            is: ([type, useItemType, positionType]: [VoucherType, boolean, PositionType]) => positionType === null  && (useItemType || type === VoucherType.DISCOUNT_VOUCHER),
            then: (schema) => schema.required(t('VALIDATION.REQUIRED')),
            otherwise: (schema) => schema.optional()
        }),
        positionType: yup.mixed<PositionType>().nullable().oneOf(Object.values(PositionType)),
        validFrom: yup.date()
            .max(yup.ref('validTo'), t('VALIDATION.START_DATE_MAX_ERROR'))
            .required(t('VALIDATION.REQUIRED')),
        validTo: yup.date()
            .min(yup.ref('validFrom'), t('VALIDATION.END_DATE_MIN_ERROR'))
            .required(t('VALIDATION.REQUIRED')),
        voucherCategory: yup.string().when('type', {
            is: (type) => type !== VoucherType.DISCOUNT_VOUCHER,
            then: (schema) => schema.required(t('VALIDATION.REQUIRED')),
            otherwise: (schema) => schema.optional(),
        }).required(t('VALIDATION.REQUIRED')),
        minimumOrderPrice: yup.number().when(['type', 'useMinimumOrderPrice'], {
            is: (type: VoucherType, useMinimumOrderPrice: boolean) => useMinimumOrderPrice && type !== VoucherType.DISCOUNT_VOUCHER,
            then: schema => schema.required(t('VALIDATION.REQUIRED'))
                .min(0, t('VALIDATION.POSITIVE_OR_ZERO')),
            otherwise: schema => schema.optional(),
        }),
        minimumOrderValue: yup.number().when(['type', 'useMinimumOrderValue'], {
            is: (type: VoucherType, useMinimumOrderValue: boolean) => useMinimumOrderValue && type !== VoucherType.DISCOUNT_VOUCHER,
            then: schema => schema.required(t('VALIDATION.REQUIRED'))
                .min(0, t('VALIDATION.POSITIVE_OR_ZERO')),
            otherwise: schema => schema.optional(),
        }),
        valueToBuy: yup.number().when('type', {
            is: (type: VoucherType) => type === VoucherType.DISCOUNT_VOUCHER,
            then: schema => schema.required(t('VALIDATION.REQUIRED'))
                .positive(t('VALIDATION.VALUE_MUST_BE_POSITIVE'))
                .integer(t('VALIDATION.INT')),
            otherwise: schema => schema.optional(),
        }),
        valueToPay: yup.number().when('type', {
            is: (type: VoucherType) => type === VoucherType.DISCOUNT_VOUCHER,
            then: schema => schema.required(t('VALIDATION.REQUIRED'))
                .positive(t('VALIDATION.VALUE_MUST_BE_POSITIVE'))
                .integer(t('VALIDATION.INT')),
            otherwise: schema => schema.optional(),
        }),
        priceReduction: yup.number().when('type', {
            is: (type: VoucherType) => type === VoucherType.FIXED_PRICE_VOUCHER,
            then: schema => schema.required(t('VALIDATION.REQUIRED'))
                .positive(t('VALIDATION.VALUE_MUST_BE_POSITIVE')),
            otherwise: schema => schema.optional(),
        }),
        percentageReduction: yup.number().when('type', {
            is: (type: VoucherType) => type === VoucherType.PERCENTAGE_VOUCHER,
            then: schema => schema.required(t('VALIDATION.REQUIRED'))
                .positive(t('VALIDATION.VALUE_MUST_BE_POSITIVE')),
            otherwise: schema => schema.optional(),
        }),
    })

    const [loadedFormValues, setLoadedFormValues] = useState<VoucherDTO | null>(null);

    useEffect(() => {
        if (!create) {
            axiosInstance.get<VoucherDTO>('/voucher/' + id).then(response => {
                const voucher = response.data;
                if(!voucher.itemType) {
                    voucher.itemType = initialFormValues.itemType;
                    voucher.useItemType = false;
                } else {
                    voucher.useItemType = true;
                }
                if(!voucher.minimumOrderPrice && voucher.minimumOrderPrice !== 0) {
                    voucher.minimumOrderPrice = 0;
                    voucher.useMinimumOrderPrice = false;
                } else {
                    voucher.useMinimumOrderPrice = true;
                }
                if(!voucher.minimumOrderValue && voucher.minimumOrderValue !== 0) {
                    voucher.minimumOrderValue = 0;
                    voucher.useMinimumOrderValue = false;
                } else {
                    voucher.useMinimumOrderValue = true;
                }
                setLoadedFormValues(response.data);
            });
        }
    }, [create, t, id, initialFormValues]);

    function submitForm(data: VoucherDTO) {
        let voucher: VoucherDTO = { ...data };
        if(!voucher.itemType) {
            voucher.itemType = null
        }
        if(!voucher.positionType) {
            voucher.positionType = null
        }
        if(voucher.type !== VoucherType.DISCOUNT_VOUCHER && !voucher.useItemType) {
            voucher.itemType = undefined
        }
        if(!voucher.useMinimumOrderPrice) {
            voucher.minimumOrderPrice = undefined;
        }
        if(!voucher.useMinimumOrderValue) {
            voucher.minimumOrderValue = undefined;
        }
        if (create) {
            createVoucher(voucher);
        } else {
            voucher.id = +id;
            editVoucher(voucher);
        }
    }

    function createVoucher(voucher: VoucherDTO) {
        axiosInstance.post('/voucher', voucher).then(() => {
            navigate('/voucher');
        });
    }

    function editVoucher(voucher: VoucherDTO) {
        axiosInstance.put('/voucher/' + voucher.id, voucher).then(() => {
            navigate('/voucher');
        });
    }

    return (
        <div>
            <Formik
                validationSchema={validationSchema}
                onSubmit={(data) => submitForm(data)}
                initialValues={loadedFormValues || initialFormValues}
                enableReinitialize
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      dirty,
                      isValid,
                      setFieldValue,
                      setFieldTouched,
                      errors,
                  }) => {
                    return (
                        <div>
                            <div className="d-flex justify-content-between align-items-center pb-3">
                                <h3>{t('VOUCHERS.' + (create ? 'CREATE' : 'EDIT'))}</h3>
                                <Button variant="primary" href="/voucher">{t('VOUCHERS.BACK')}</Button>
                            </div>
                            <Form onSubmit={handleSubmit} className={styles.form} noValidate>
                                <Form.Group className="pb-3" controlId="typeControl">
                                    <Form.Label>{t("VOUCHERS.TYPE")}</Form.Label>
                                    <Form.Select name="type"
                                                 value={values.type}
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}
                                                 isInvalid={!!(touched.type && errors.type)}
                                                 required>
                                        {voucherTypes.map((type: VoucherType) =>
                                            <option key={type} value={type}>
                                                {t("ENUMS.VOUCHER_TYPES." + type)}
                                            </option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.type}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="nameControl">
                                    <Form.Label>{t("VOUCHERS.NAME")}</Form.Label>
                                    <Form.Control name="name"
                                                  type="text"
                                                  value={values.name}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.name && errors.name)}
                                                  required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="codeControl">
                                    <Form.Label>{t("VOUCHERS.CODE")}</Form.Label>
                                    <Form.Control name="code"
                                                  type="text"
                                                  value={values.code}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.code && errors.code)}
                                                  required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.code}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="itemTypeControl">
                                    <Form.Label>
                                        {values.type !== VoucherType.DISCOUNT_VOUCHER ?
                                            <Form.Switch
                                                type="checkbox"
                                                name="useItemType"
                                                checked={values.useItemType}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                label={t("VOUCHERS.ITEM_TYPE")}
                                            />
                                            : t("VOUCHERS.ITEM_TYPE")}
                                    </Form.Label>
                                    <Form.Select name="itemType"
                                                 value={values.itemType}
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}
                                                 disabled={(!!values.positionType) || (values.type === VoucherType.DISCOUNT_VOUCHER ? false : !values.useItemType)}
                                                 isInvalid={!!(touched.itemType && errors.itemType)}
                                                 required>
                                        <option value={""}>-</option>
                                        {itemTypes.map((itemType: VoucherItemType) =>
                                            <option key={itemType} value={itemType}>
                                                {t("ENUMS.VOUCHER_ITEM_TYPES." + itemType)}
                                            </option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.itemType}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="positionTypeControl">
                                    <Form.Label>
                                        {t("VOUCHERS.POSITION_TYPE")}
                                    </Form.Label>
                                    <Form.Select name="positionType"
                                                 value={values.positionType}
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}
                                                 isInvalid={!!(touched.positionType && errors.positionType)}
                                                 disabled={!!values.itemType}>
                                        <option value={""}>-</option>
                                        {positionTypes.map((positionType) =>
                                            <option key={positionType} value={positionType}>
                                                {t("ENUMS.VOUCHER_POSITION_TYPES." + positionType)}
                                            </option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.positionType}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="validFromControl">
                                    <Form.Label>{t("VOUCHERS.VALID_FROM")}</Form.Label>
                                    <Datetime value={moment(values.validFrom)}
                                              onChange={e => {
                                                  setFieldValue("validFrom", e);
                                                  setFieldTouched("validFrom");
                                              }}
                                              inputProps={(touched.validFrom && errors.validFrom) ?
                                                  {
                                                      required: true,
                                                      name: "validFrom",
                                                      className: 'form-control is-invalid'
                                                  } : {
                                                      required: true,
                                                      name: 'validFrom',
                                                      className: "form-control"
                                                  }
                                              }
                                              timeFormat={'HH:mm'}
                                              closeOnSelect={true}
                                              renderInput={(props) => {
                                                  return <input {...props} value={props.value} />;
                                              }}
                                    />
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="validToControl">
                                    <Form.Label>{t("VOUCHERS.VALID_TO")}</Form.Label>
                                    <Datetime value={moment(values.validTo)}
                                              onChange={e => {
                                                  setFieldValue("validTo", e);
                                                  setFieldTouched("validTo");
                                              }}
                                              inputProps={(touched.validTo && errors.validTo) ?
                                                  {
                                                      required: true,
                                                      name: "validTo",
                                                      className: 'form-control is-invalid'
                                                  } : {
                                                      required: true,
                                                      name: 'validTo',
                                                      className: 'form-control'
                                                  }
                                              }
                                              timeFormat={'HH:mm'}
                                              closeOnSelect={true}
                                    />
                                </Form.Group>

                                {values.type === VoucherType.DISCOUNT_VOUCHER &&
                                  <><Form.Group className="pb-3" controlId="valueToBuyControl">
                                    <Form.Label>{t("VOUCHERS.VALUE_TO_BUY")}</Form.Label>
                                    <Form.Control name="valueToBuy"
                                                  type="number"
                                                  value={values.valueToBuy}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.valueToBuy && errors.valueToBuy)}
                                                  required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.valueToBuy}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                    <Form.Group className="pb-3" controlId="valueToPayControl">
                                      <Form.Label>{t("VOUCHERS.VALUE_TO_PAY")}</Form.Label>
                                      <Form.Control name="valueToPay"
                                                    type="number"
                                                    value={values.valueToPay}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={!!(touched.valueToPay && errors.valueToPay)}
                                                    required>
                                      </Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                          {errors.valueToPay}
                                      </Form.Control.Feedback>
                                    </Form.Group></>
                                }
                                {(values.type === VoucherType.FIXED_PRICE_VOUCHER || values.type === VoucherType.PERCENTAGE_VOUCHER) &&
                                  <>
                                    <Form.Group className="pb-3" controlId="voucherCategoryControl">
                                      <Form.Label>{t("VOUCHERS.CATEGORY")}</Form.Label>
                                      <Form.Select name="voucherCategory"
                                                   value={values.voucherCategory}
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   isInvalid={!!(touched.voucherCategory && errors.voucherCategory)}
                                                   required>
                                          {voucherCategories.map((category: VoucherCategory) =>
                                              <option key={category} value={category}>
                                                  {t("ENUMS.VOUCHER_CATEGORIES." + category)}
                                              </option>
                                          )}
                                      </Form.Select>
                                      <Form.Control.Feedback type="invalid">
                                          {errors.voucherCategory}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="pb-3" controlId="minimumOrderPriceControl">
                                      <Form.Label>
                                        <Form.Switch
                                          type="checkbox"
                                          name="useMinimumOrderPrice"
                                          checked={values.useMinimumOrderPrice}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          label={t("VOUCHERS.MINIMUM_ORDER_PRICE")}
                                        />
                                      </Form.Label>
                                      <InputGroup>
                                        <Form.Control name="minimumOrderPrice"
                                                      type="number"
                                                      value={values.minimumOrderPrice}
                                                      disabled={!values.useMinimumOrderPrice}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={!!(touched.minimumOrderPrice && errors.minimumOrderPrice)}
                                                      required>
                                        </Form.Control>
                                        <InputGroup.Text className={styles.roundedCorner}>€</InputGroup.Text>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.minimumOrderPrice}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="pb-3" controlId="minimumOrderValueControl">
                                      <Form.Label>
                                        <Form.Switch
                                          type="checkbox"
                                          name="useMinimumOrderValue"
                                          checked={values.useMinimumOrderValue}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          label={t("VOUCHERS.MINIMUM_ORDER_VALUE")}
                                        />
                                      </Form.Label>
                                      <Form.Control name="minimumOrderValue"
                                                    type="number"
                                                    value={values.minimumOrderValue}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={!values.useMinimumOrderValue}
                                                    isInvalid={!!(touched.minimumOrderValue && errors.minimumOrderValue)}
                                                    required>
                                      </Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                          {errors.minimumOrderValue}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </>
                                }
                                {values.type === VoucherType.FIXED_PRICE_VOUCHER &&
                                  <><Form.Group className="pb-3" controlId="priceReductionControl">
                                    <Form.Label>{t("VOUCHERS.PRICE_REDUCTION")}</Form.Label>
                                    <InputGroup>
                                      <Form.Control name="priceReduction"
                                                    type="number"
                                                    value={values.priceReduction}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={!!(touched.priceReduction && errors.priceReduction)}
                                                    required>
                                      </Form.Control>
                                      <InputGroup.Text className={styles.roundedCorner}>€</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                          {errors.priceReduction}
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Form.Group></>
                                }
                                {values.type === VoucherType.PERCENTAGE_VOUCHER &&
                                  <><Form.Group className="pb-3" controlId="percentageReductionControl">
                                    <Form.Label>{t("VOUCHERS.PERCENTAGE_REDUCTION")}</Form.Label>
                                    <InputGroup>
                                      <Form.Control name="percentageReduction"
                                                    type="number"
                                                    value={values.percentageReduction}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={!!(touched.percentageReduction && errors.percentageReduction)}
                                                    required>
                                      </Form.Control>
                                      <InputGroup.Text className={styles.roundedCorner}>%</InputGroup.Text>
                                      <Form.Control.Feedback type="invalid">
                                          {errors.percentageReduction}
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Form.Group></>
                                }
                                <Button variant="primary" type="submit" disabled={!(dirty && isValid)}>
                                    {t('VOUCHERS.SAVE')}
                                </Button>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    );
}

export default VoucherDetails;
