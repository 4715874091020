import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../api";
import { RatepayOrderDTO, RatepayOrderListDTO } from "./RatePayOrderListDTO";
import { ListPagination } from "../shared/ListPagination";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import CustomModal from "../shared/CustomModal";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { toast } from "react-toastify";
import styles from './RatePay.module.scss';

export function RatePay() {
    const [t] = useTranslation();
    const [searchState, setSearchState] = useState<{ ordernumber?: string; page: number }>({
        page: 0,
    });
    const [orders, setOrders] = useState<RatepayOrderListDTO>();
    const [modal, setModal] = useState<{ open: boolean; confirm?: boolean; order?: RatepayOrderDTO }>({ open: false });
    const [searchString, setSearchString] = useState<string>("");
    const loadOnChange = useCallback(loadData, [searchState]);
    function loadData() {
        axiosInstance
            .get<RatepayOrderListDTO>("order/ratepay", { params: searchState })
            .then((res) => setOrders(res.data));
    }

    useEffect(() => {
        loadOnChange();
    }, [searchState, loadOnChange]);

    function showRejectModal(order: RatepayOrderDTO) {
        setModal({
            open: true,
            order: order,
            confirm: false,
        });
    }
    function showConfirmModal(order: RatepayOrderDTO) {
        setModal({
            open: true,
            order: order,
            confirm: true,
        });
    }

    function confirmPayment(id: number) {
        toast
            .promise(axiosInstance.put(`order/paymentConfirmation/${id}`).then(), {
                pending: t("RATE_PAY.TOAST.UPDATING"),
                success: t("RATE_PAY.TOAST.CONFIRMED"),
                error: t("RATE_PAY.TOAST.ERROR"),
            })
            .finally(() => {
                setModal({ open: false });
                loadData();
            });
    }

    function rejectPayment(id: number) {
        toast
            .promise(axiosInstance.put(`order/paymentRejection/${id}`).then(), {
                pending: t("RATE_PAY.TOAST.UPDATING"),
                success: t("RATE_PAY.TOAST.REJECTED"),
                error: t("RATE_PAY.TOAST.ERROR"),
            })
            .finally(() => {
                setModal({ open: false });
                loadData();
            });
    }

    function search() {
        setSearchState({ page: 0, ordernumber: searchString })
    }

    return (
        <>
            {orders && (
                <div className={styles.ratePayContainer}>
                    <div className="row">
                        <div className="col-4">
                            <input
                                type="text"
                                className="form-control"
                                value={searchString}
                                placeholder={t("RATE_PAY.ORDER_ID")}
                                onChange={(e) => setSearchString(e.target.value)}
                                onKeyDown={event => event.key === 'Enter' && search()}
                            />
                        </div>
                        <div className="col-4">
                            <button
                                className="btn btn-primary"
                                onClick={() => search()}
                            >
                                {t("RATE_PAY.SEARCH")}
                            </button>
                        </div>
                    </div>

                    <ListPagination
                        currentPage={searchState.page}
                        totPages={orders?.totalPages}
                        pageClicked={(page: number) => setSearchState({ ...searchState, page })}
                    />
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>{t("RATE_PAY.ORDER_ID")}</th>
                                <th className="text-end">{t("RATE_PAY.PRICE")}</th>
                                <th>{t("RATE_PAY.PAYMENT_STATUS")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.orders.map((order) => {
                                return (
                                    <tr key={order.id}>
                                        <td className="align-middle">{order.id}</td>
                                        <td className="align-middle text-end">
                                            <NumericFormat
                                                value={order.priceGross}
                                                displayType="text"
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                suffix="€"
                                            />
                                        </td>
                                        <td className="align-middle">{t('ENUMS.PAYMENT_STATUS.'+order.paymentStatus)}</td>
                                        <td className="text-end">
                                            <button
                                                className="btn btn-warning me-2"
                                                onClick={() => showRejectModal(order)}
                                            >
                                                {t("RATE_PAY.REJECT")}
                                            </button>
                                            <button className="btn btn-primary" onClick={() => showConfirmModal(order)}>
                                                {t("RATE_PAY.CONFIRM")}
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <ListPagination
                        currentPage={searchState.page}
                        totPages={orders?.totalPages}
                        pageClicked={(page: number) => setSearchState({ ...searchState, page })}
                    />
                    <CustomModal open={modal.open} backdropClick={() => setModal({ open: false })}>
                        {modal.confirm ? (
                            <ConfirmationModal
                                translationPrefix={"RATE_PAY.CONFIRM_MODAL"}
                                confirmClick={() => confirmPayment(modal.order!.id)}
                                cancelClick={() => setModal({ open: false })}
                                bodyParams={modal.order}
                            ></ConfirmationModal>
                        ) : (
                            <ConfirmationModal
                                translationPrefix={"RATE_PAY.REJECT_MODAL"}
                                confirmClick={() => rejectPayment(modal.order!.id)}
                                cancelClick={() => setModal({ open: false })}
                                bodyParams={modal.order}
                            ></ConfirmationModal>
                        )}
                    </CustomModal>
                </div>
            )}
        </>
    );
}
