import React from 'react';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import * as yup from 'yup';
import {Button, Form} from 'react-bootstrap';
import styles from './Login.module.scss';
import {LoginCredentials} from './models/LoginCredentials';
import axios from 'axios';
import {UserDTO} from './models/UserDTO';

function Login({setToken}: any) {
    const [t] = useTranslation();

    const validationSchema = yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required()
    });

    const initialValues: LoginCredentials = {
        username: '',
        password: ''
    };

    function submitForm(credentials: LoginCredentials, {resetForm}: any) {
        const userToken =  btoa(credentials.username + ':' + credentials.password);
        axios.post<UserDTO>('/api/login', {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + userToken
            }
        }).then(response => {
            console.log(response.data);
            setToken(userToken);
        }, () => {
            resetForm({});
        })
    }

    return (
        <>
            <Formik validationSchema={validationSchema}
                    onSubmit={(data, {resetForm}) => submitForm(data, {resetForm})}
                    initialValues={initialValues}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    dirty,
                    isValid,
                    errors
                }) => {
                    return (
                        <div className={styles.loginWrapper}>
                            <h2>{t('LOGIN.TITLE')}</h2>
                            <Form onSubmit={handleSubmit} className={styles.loginForm} noValidate>
                                <Form.Group controlId="usernameControl">
                                    <Form.Label>{t('LOGIN.USERNAME')}</Form.Label>
                                    <Form.Control name="username"
                                                  type="text"
                                                  value={values.username}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.username && errors.username)}
                                                  required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.username}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="passwordControl">
                                    <Form.Label>{t('LOGIN.PASSWORD')}</Form.Label>
                                    <Form.Control name="password"
                                                  type="password"
                                                  value={values.password}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.password && errors.password)}
                                                  required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-4 w-100" disabled={!(dirty && isValid)}>
                                    {t('LOGIN.LOGIN')}
                                </Button>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </>
    )
}

export default Login;
