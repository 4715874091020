import React, { useEffect, useRef, useState } from "react";
import { useMatch } from "react-router-dom";

export function NavDropdown(
    props: React.PropsWithChildren<{ to: string; label: string }>
) {
    const wrapperRef = useRef<any>(null);
    const [open, setOpen] = useState(false);
    const match = useMatch(props.to);

    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <li
            className={"nav-item dropdown " + (open ? "show" : "")}
            onClick={() => setOpen(!open)}
            ref={wrapperRef}
        >
      <span className={"nav-link dropdown-toggle" + (match ? " active" : "")}>
        {props.label}
      </span>
            <div
                className={"dropdown-menu " + (open ? "show" : "")}
                aria-labelledby="navbarDropdown"
            >
                {props.children}
            </div>
        </li>
    );
}
