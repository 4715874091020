import { Trans, useTranslation } from "react-i18next";

export function ConfirmationModal({
    translationPrefix,
    confirmClick,
    cancelClick,
    bodyParams
}: {
    translationPrefix: string;
    confirmClick: Function;
    cancelClick: Function;
    bodyParams?: any;
}) {
    const [t] = useTranslation();
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{t(translationPrefix + ".TITLE")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => cancelClick()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">{Trans(t(translationPrefix + ".BODY", bodyParams))}</div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => cancelClick()}>
                        {t(translationPrefix + ".CANCEL")}
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => confirmClick()}>
                        {t(translationPrefix + ".CONFIRM")}
                    </button>
                </div>
            </div>
        </div>
    );
}
