import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ShippingDTO} from '../models/ShippingDTO';
import { useNavigate, useParams } from "react-router-dom";
import {Button, Form, InputGroup} from 'react-bootstrap';
import styles from './ShippingCostDetails.module.scss';
import axiosInstance from '../../../api';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Formik} from 'formik';
import * as yup from 'yup';

const countryCodes = ["AD", "AE", "AF", "AG", "AI", "AL", "AM", "AN", "AO", "AQ", "AR", "AS", "AT", "AU", "AW", "AX",
    "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ", "BR", "BS", "BT", "BV",
    "BW", "BY", "BZ", "CA", "CC", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CS", "CU", "CV",
    "CW", "CX", "CY", "CZ", "DE", "DJ", "DK", "DM", "DO", "DZ", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "FI", "FJ",
    "FK", "FM", "FO", "FR", "GA", "GB", "GD", "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR", "GS",
    "GT", "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT", "HU", "ID", "IE", "IL", "IM", "IN", "IO", "IQ", "IR", "IS",
    "IT", "JE", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM", "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC",
    "LI", "LK", "LR", "LS", "LT", "LU", "LV", "LY", "MA", "MC", "MD", "ME", "MF", "MG", "MH", "MK", "ML", "MM", "MN",
    "MO", "MP", "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ", "NA", "NC", "NE", "NF", "NG", "NI", "NL",
    "NO", "NP", "NR", "NU", "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PM", "PN", "PR", "PS", "PT", "PW",
    "PY", "QA", "RE", "RO", "RS", "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI", "SJ", "SK", "SL", "SM",
    "SN", "SO", "SR", "SS", "ST", "SV", "SX", "SY", "SZ", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL", "TM", "TN",
    "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "UM", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI", "VN", "VU",
    "WF", "WS", "XK", "YE", "YT", "ZA", "ZM", "ZW"];
type CountryOptionType = {
    code: string | undefined
    label: string
}

type ShippingFormValues = {
    country: CountryOptionType[]
    costs: number | undefined
    freeShipping: boolean | undefined
}

function ShippingCostDetails() {
    const [t] = useTranslation();
    const navigate = useNavigate();
    let {country} = useParams<{ country: string }>();
    const create = country === 'create';
    const countryOptions: CountryOptionType[] = countryCodes.map(c => ({
        code: c,
        label: t('ENUMS.COUNTRIES.' + c)
    }));
    const validationSchema = yup.object({
        country: yup.array().ensure()
            .length(1, t('SHIPPING_COSTS.PLEASE_CHOOSE_COUNTRY'))
            .of(yup.object().shape({
                code: yup.string().required(),
                label: yup.string().required()
            })).required(t('SHIPPING_COSTS.PLEASE_CHOOSE_COUNTRY')),
        costs: yup.number().min(0, t('VALIDATION.VALUE_MUST_BE_POSITIVE')).required(t('VALIDATION.REQUIRED')),
        freeShipping: yup.bool()
    });
    const initialFormValues: ShippingFormValues = {
        country: [],
        costs: 0,
        freeShipping: false
    }
    const [loadedFormValues, setLoadedFormValues] = useState<ShippingFormValues | null>(null);
    const [id, setId] = useState<any>(null);

    useEffect(() => {
        if (!create) {
            axiosInstance.get<ShippingDTO>('/shipping/' + country).then(response => {
                setLoadedFormValues({
                    country: [{
                        code: response.data.country,
                        label: t('ENUMS.COUNTRIES.' + response.data.country)
                    }],
                    costs: response.data.costs,
                    freeShipping: response.data.freeShipping
                });
                setId(response.data.id);
            });
        }
    }, [create, t, country]);

    function submitForm(data: any) {
        let shippingCosts: ShippingDTO = {
            country: data.country[0].code,
            costs: data.costs,
            freeShipping: data.freeShipping
        };
        if (create) {
            createShippingCosts(shippingCosts);
        } else {
            shippingCosts.id = id;
            editShippingCosts(shippingCosts);
        }
    }

    const createShippingCosts = (shippingCost: ShippingDTO) => {
        axiosInstance.post('/shipping', shippingCost).then(() => {
            navigate('/shipping-costs');
        });
    }

    const editShippingCosts = (shippingCost: ShippingDTO) => {
        axiosInstance.put('/shipping/' + country, shippingCost).then(() => {
            navigate('/shipping-costs');
        });
    }

    return (
        <div>
            <Formik
                validationSchema={validationSchema}
                onSubmit={(data) => submitForm(data)}
                initialValues={loadedFormValues || initialFormValues}
                enableReinitialize
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      dirty,
                      isValid,
                      setFieldValue,
                      errors,
                  }) => {

                    return (
                        <div>
                            <div className="d-flex justify-content-between align-items-center pb-3">
                                <h3>{t('SHIPPING_COSTS.' + (create ? 'CREATE' : 'EDIT'))}</h3>
                                <Button variant="primary" href="/shipping-costs">{t('SHIPPING_COSTS.BACK')}</Button>
                            </div>

                            <Form onSubmit={handleSubmit} className={styles.form} noValidate>
                                <Form.Group className="pb-3">
                                    <Form.Label>{t('SHIPPING_COSTS.COUNTRY')}</Form.Label>
                                    <Typeahead options={countryOptions}
                                               id="country-autocomplete"
                                               disabled={!create}
                                               inputProps={{required: true, name: 'country'}}
                                               clearButton
                                               paginate={false}
                                               placeholder={t('SHIPPING_COSTS.PLEASE_CHOOSE_COUNTRY')}
                                               emptyLabel={t('SHIPPING_COSTS.NO_MATCHED_COUNTRIES')}
                                               onChange={e => {
                                                   setFieldValue('country', e);
                                               }}
                                               onBlur={handleBlur}
                                               selected={values.country}
                                               isInvalid={!!errors.country}/>
                                    <Form.Control.Feedback type="invalid">
                                        <>{errors.country}</>
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="costsControl">
                                    <Form.Label>{t('SHIPPING_COSTS.COSTS')}</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="number"
                                                      placeholder="0,00"
                                                      disabled={values.freeShipping}
                                                      name="costs"
                                                      min="0"
                                                      required
                                                      value={values.costs}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={!!errors.costs}/>
                                        <InputGroup.Text>€</InputGroup.Text>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.costs}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="freeShippingControl">
                                    <Form.Check type="checkbox"
                                                label={t('SHIPPING_COSTS.FREE_SHIPPING')}
                                                name="freeShipping"
                                                checked={values.freeShipping}
                                                onChange={handleChange}
                                                onBlur={handleBlur}/>
                                </Form.Group>
                                <Button variant="primary" type="submit" disabled={!(dirty && isValid)}>
                                    {t('SHIPPING_COSTS.SAVE')}
                                </Button>
                            </Form>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )
}

export default ShippingCostDetails;
