import {useTranslation} from "react-i18next";
import CustomModal from "../shared/CustomModal";
import {useState} from "react";
import styles from './userManagement.module.scss';
import {toast} from "react-toastify";
import axiosInstance from "../../api";

export default function DeleteUser() {
    const [t] = useTranslation();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [email, setEmail] = useState("");

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function deleteUser() {
        toast
            .promise(axiosInstance.delete(`user/byemail/${email}`).then(), {
                pending: t("USER_MANAGEMENT.DELETION.PENDING"),
                success: t("USER_MANAGEMENT.DELETION.SUCCESS"),
                error: t("USER_MANAGEMENT.DELETION.ERROR"),
            })
            .finally(() => {
                setOpenModal(false);
            });
    }

    return <section className={"delete-user container"}>
        <h3>Account löschen</h3>
        <hr/>
        <label>
            {t('USER_MANAGEMENT.DELETION.TARGET_ACCOUNT_EMAIL')}
        </label>
        <input className={"form-input d-block form-control mb-2 mt-1 "} id={"target-email"}
               onChange={(e) => setEmail(e.target.value)} required={true}/>
        <button className={'btn btn-primary'}
                disabled={!isValidEmail(email)}
                onClick={() => setOpenModal(true)}>{t('USER_MANAGEMENT.DELETION.DELETE')}</button>
        <CustomModal open={openModal} backdropClick={() => setOpenModal(false)}>
            <div className={styles.deleteConfirmModal}>
                <h5>{t('USER_MANAGEMENT.DELETION.CONFIRM_HEADER')}</h5>
                <hr/>
                <p>{t('USER_MANAGEMENT.DELETION.CONFIRM_TEXT', {account: email})}</p>
                <div className={"d-flex justify-content-between"}>
                    <button className={"btn"} onClick={() => deleteUser()}>{t('USER_MANAGEMENT.DELETION.YES')}</button>
                    <button className={"btn"} onClick={() => setOpenModal(false)}>{t('USER_MANAGEMENT.DELETION.NO')}</button>
                </div>
            </div>
        </CustomModal>
    </section>
}