import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {ShoppingOverviewContext} from "./ShippingOverview";

export interface ShippingOverviewModalProps {
    closeHandler: Function;
    saveHandler: Function;
}


export default function ShippingOverviewModal({closeHandler, saveHandler}: ShippingOverviewModalProps) {
    const {t} = useTranslation();
    const {
        selectedOrder,
        parcelCarrierList,
    } = useContext(ShoppingOverviewContext);

    const [inputFields, setInputFields] = useState<string[]>(['']);
    const [selectedCarrierId, setSelectedCarrierId] = useState<number>(selectedOrder.carrierId || 1);


    useEffect(() => {
        setInputFields(
            selectedOrder.trackingReferences.length === 0
                ? [''] : selectedOrder.trackingReferences.map(x => x.trackingReference)
        );

       if(!selectedOrder.carrierId){
           selectedOrder.carrierId = 1;
       }
    }, [selectedOrder]);

    function handleInputChange(index, event) {
        const values = [...inputFields];
        values[index] = event.target.value;
        setInputFields(values);

        if (event.target.value !== '') {
            selectedOrder.trackingReferences = values.map(x => ({trackingLink: '', trackingReference: x}));

            if (index === inputFields.length - 1) {
                setInputFields([...inputFields, '']);
            }
        }
    };

    function handleBlur(index) {
        if (inputFields[index] === '' && index !== inputFields.length - 1) {
            const newFields = [...inputFields];
            newFields.splice(index, 1);
            setInputFields(newFields);
        }
    }


    function onSave() {
        selectedOrder.carrierId = selectedCarrierId;
        selectedOrder.trackingReferences = inputFields.filter(ref => ref != '').map(ref => ({
            trackingLink: '',
            trackingReference: ref
        }));
        saveHandler();
    }


    return <>
        <div className="modal-dialog">
            <div className={"shopping-modal modal-content"}>
                <div className="modal-header shopping-modal-header">
                    <h5 className="modal-title">{t("SHIPPING_OVERVIEW.ORDER_ID")} {selectedOrder.id}</h5>
                    <button type="button" className="close btn-close"
                            aria-label="Close" onClick={() => closeHandler()}></button>
                </div>
                <div className={" modal-body"}>
                    <div className={"my-3"}>
                        <label className={"mb-1 fw-bold"}>{t('SHIPPING_OVERVIEW.TRACKING_SERVICE')}</label>
                        <select id={"tracking-service"} className={"form-select "} value={selectedCarrierId}
                                onChange={(e) => setSelectedCarrierId(Number(e.target.value))}>
                            {parcelCarrierList.map(service =>
                                <option value={service.id}
                                        key={selectedOrder.id + "-" + service.id}>{service.name}</option>
                            )}
                        </select>
                    </div>

                    <label className={"fw-bold"}>{t('SHIPPING_OVERVIEW.TRACKING_INFO')}</label>
                    <hr className={"mt-0 "}/>
                    <div className={"input-area"}>
                        {selectedOrder.id && inputFields.map((input, index) => (

                            <input
                                key={selectedOrder.id + "-" + index}
                                type="text"
                                className="form-control mb-3 "
                                value={inputFields[index]}
                                onChange={(event) => handleInputChange(index, event)}
                                onBlur={() => handleBlur(index)}
                                placeholder={t('SHIPPING_OVERVIEW.TRACKING_INFO') + ' ' + (index + 1)}
                            />
                        ))}
                    </div>
                    <hr/>
                </div>

                <div className={"modal-footer d-flex justify-content-end"}>
                    <button type="button" className="btn btn-primary me-3" onClick={onSave}>
                        {t("SHIPPING_OVERVIEW.SAVE")}
                    </button>
                    <button type="button" className={"btn btn-secondary "} onClick={() => closeHandler()}>
                        {t("SHIPPING_OVERVIEW.CANCEL")}
                    </button>
                </div>
            </div>
        </div>
    </>
}