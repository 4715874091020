import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {useTranslation} from 'react-i18next';
import { useNavigate } from "react-router-dom";
import {OfferSliderEntryDTO} from './models/OfferSliderEntryDTO';
import axiosInstance from '../../api';
import moment from 'moment';
import styles from './OfferSlidersManagement.module.scss';
import {DragDropContext, Droppable, Draggable, DropResult} from "react-beautiful-dnd";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getItemStyle = (draggableStyle: any, isDragging: any) => ({
    userSelect: 'none',
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle
});

const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? 'lightgreen' : 'white'
});

function OfferSlidersManagement() {
    const [sliders, setSliders] = useState<OfferSliderEntryDTO[]>([]);
    const [t] = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        getOfferSliders();
    }, []);

    function getOfferSliders() {
        axiosInstance.get<OfferSliderEntryDTO[]>('/offerslider').then(response => {
            setSliders(response.data);
        });
    }

    function goToDetailPage(id: number | undefined) {
        navigate('/offer-sliders/' + id);
    }

    function handleDelete(id: number | undefined, event: any) {
        event.stopPropagation();
        axiosInstance.delete('/offerslider/' + id).then(() => {
            getOfferSliders();
        });
    }

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            sliders,
            result.source.index,
            result.destination.index
        );
        setSliders(items);
        const sliderId = sliders[result.source.index].id;
        axiosInstance.put('/offerslider/' + sliderId + '/sorting?newSortIndex=' + result.destination.index).then(() => {
            getOfferSliders();
        });
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center pb-3">
                <h3>{t('NAVIGATION.OFFER_SLIDERS')}</h3>
                <Button variant="primary" href="/offer-sliders/create">{t('OFFER_SLIDERS.NEW')}</Button>
            </div>
            <div className={styles.listHeader}>
                <div className={styles.imgContainer}>{t('OFFER_SLIDERS.IMAGE')}</div>
                <div className={styles.link}>{t('OFFER_SLIDERS.LINK')}</div>
                <div className={styles.hoverText}>{t('OFFER_SLIDERS.HOVER_TEXT')}</div>
                <div className={styles.date}>{t('OFFER_SLIDERS.VALID_FROM')}</div>
                <div className={styles.date}>{t('OFFER_SLIDERS.VALID_TO')}</div>
                <div className={styles.btnContainer}>&nbsp;</div>
            </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                 style={getListStyle(snapshot.isDraggingOver)}
                                  className="w-100"
                            >
                                {sliders.map((item: OfferSliderEntryDTO, index) => (
                                    <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                        {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={getItemStyle(
                                                        provided.draggableProps.style,
                                                        snapshot.isDragging
                                                    )}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                >
                                                    <div className={styles.listContent}>
                                                        <div className={styles.imgContainer}>
                                                            <img src={'/api/offerslider/' + item.imageId + '/image'} alt='img' className={styles.dndImg}/>
                                                        </div>
                                                        <div className={styles.link}>{item.link}</div>
                                                        <div className={styles.hoverText}>{item.hoverText}</div>
                                                        <div className={styles.date}>{moment(item.validFrom).format('DD.MM.YYYY')}</div>
                                                        <div className={styles.date}>{moment(item.validTo).format('DD.MM.YYYY')}</div>
                                                        <div className={styles.btnContainer}>
                                                            <Button variant="primary" className="m-1" onClick={() => goToDetailPage(item.id)}>
                                                                {t('OFFER_SLIDERS.EDIT')}
                                                            </Button>
                                                            <Button variant="primary" className="m-1" onClick={(e) => handleDelete(item.id, e)}>
                                                                {t('OFFER_SLIDERS.DELETE')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                        )}
                                    </Draggable>

                                ))}
                                {provided.placeholder}
                            </div>
                        )}

                    </Droppable>
                </DragDropContext>
        </div>
    );
}

export default OfferSlidersManagement;
