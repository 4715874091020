import axios from 'axios';

const defaultOptions = {
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    },
};
const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(function (config) {
    const item = localStorage.getItem('token');
    if(item) {
        config!.headers!.Authorization = item;
    }
    return config;
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
    }
    throw error;
})

export default axiosInstance;

