import React from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

require("./index.scss");

const app = document.getElementById("root");
const root = createRoot(app!);
const jsx = (<>
        <App />
        <ToastContainer />
    </>
);
root.render(jsx);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
