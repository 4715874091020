import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import deTranslations from "./assets/i18n/de.json";

const resources = {
    de: {
        translation: deTranslations
    },
    /*en: {
        translation: {
            "Welcome to React": "Welcome to React and react-i18next"
        }
    }*/
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "de",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
