import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import {VoucherDTO, VoucherType} from './models/VoucherDTO';
import {useTranslation} from 'react-i18next';
import axiosInstance from '../../api';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import styles from '../ShippingCosts/ShippingCosts.module.scss';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';

function VoucherManagement() {
    const [vouchers, setVouchers] = useState<VoucherDTO[]>([]);
    const [t] = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        getVouchers();
    }, []);

    const goToDetailPage = (id: number | undefined) => {
        navigate('/voucher/' + id);
    }

    const handleDelete = (id: number | undefined, event: any) => {
        event.stopPropagation();
        axiosInstance.delete('/voucher/' + id).then(response => {
            getVouchers();
        });
    }

    const getVouchers = () => {
        axiosInstance.get<VoucherDTO[]>('/voucher').then(response => {
            setVouchers(response.data);
        });
    }

    function renderPriceReduction(voucher: VoucherDTO) {
        switch (voucher.type) {
            case VoucherType.PERCENTAGE_VOUCHER:
                return <NumericFormat value={voucher.percentageReduction} displayType={'text'} decimalSeparator={','}
                                     decimalScale={2} fixedDecimalScale={true} suffix={'%'}/>;
            case VoucherType.FIXED_PRICE_VOUCHER:
                return <NumericFormat value={voucher.priceReduction} displayType={'text'} decimalSeparator={','}
                                     decimalScale={2} fixedDecimalScale={true} suffix={'€'}/>
        }
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center pb-3">
                <h3>{t('NAVIGATION.VOUCHERS')}</h3>
                <Button variant="primary" href="/voucher/create">{t('VOUCHERS.NEW')}</Button>
            </div>
            <Table striped hover>
                <thead>
                <tr>
                    <th>{t('VOUCHERS.NAME')}</th>
                    <th>{t('VOUCHERS.TYPE')}</th>
                    <th>{t('VOUCHERS.CODE')}</th>
                    <th>{t('VOUCHERS.VALID_FROM')}</th>
                    <th>{t('VOUCHERS.VALID_TO')}</th>
                    <th>{t('VOUCHERS.ITEM_TYPE')}</th>
                    <th>{t('VOUCHERS.POSITION_TYPE')}</th>
                    <th>{t('VOUCHERS.CATEGORY')}</th>
                    <th className="text-end">{t('VOUCHERS.VALUE_TO_BUY')}</th>
                    <th className="text-end">{t('VOUCHERS.VALUE_TO_PAY')}</th>
                    <th className="text-end">{t('VOUCHERS.MINIMUM_ORDER_PRICE')}</th>
                    <th className="text-end">{t('VOUCHERS.MINIMUM_ORDER_VALUE')}</th>
                    <th className="text-end">{t('VOUCHERS.REDUCTION')}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {vouchers.map((item: VoucherDTO) =>
                    <tr className={styles.click} key={item.id} onClick={() => goToDetailPage(item.id)}>
                        <td>{item.name}</td>
                        <td>{t('ENUMS.VOUCHER_TYPES.' + item.type)}</td>
                        <td>{item.code}</td>
                        <td>{moment(item.validFrom).format('DD.MM.YYYY')}</td>
                        <td>{moment(item.validTo).format('DD.MM.YYYY')}</td>
                        <td>{item.itemType ? t('ENUMS.VOUCHER_ITEM_TYPES.' + item.itemType) : ''}</td>
                        <td>{item.positionType ? t('ENUMS.VOUCHER_POSITION_TYPES.' + item.positionType) : ''}</td>
                        <td>{item.voucherCategory ? t('ENUMS.VOUCHER_CATEGORIES.' + item.voucherCategory) : ''}</td>
                        <td className="text-end">{item.valueToBuy}</td>
                        <td className="text-end">{item.valueToPay}</td>
                        <td className="text-end">
                            <NumericFormat value={item.minimumOrderPrice} displayType={'text'} decimalSeparator={','}
                                          decimalScale={2} fixedDecimalScale={true} suffix={'€'}/>
                        </td>
                        <td className="text-end">{item.minimumOrderValue}</td>
                        <td className="text-end">
                            {renderPriceReduction(item)}
                        </td>
                        <td className="text-end">
                            <Button variant="primary" onClick={(e) => handleDelete(item.id, e)}>
                                {t('VOUCHERS.DELETE')}
                            </Button></td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
}

export default VoucherManagement;
