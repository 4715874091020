import './ShippingOverview.scss';
import {ListPagination} from "../shared/ListPagination";
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";
import {createContext, useCallback, useEffect, useState} from "react";
import axiosInstance from "../../api";
import {ParcelCarrierDTO, ShippingOrder, ShippingOrderDTO} from "./ShippingOverviewModels";
import CustomModal from "../shared/CustomModal";
import ShippingOverviewModal from "./ShippingOverviewModal";

export const ShoppingOverviewContext = createContext({
    selectedOrder: {} as ShippingOrder,
    parcelCarrierList: [] as ParcelCarrierDTO[],
})

export default function ShippingOverview() {
    const [t] = useTranslation();
    const [searchState, setSearchState] = useState<{ orderId?: string; page: number, showAll?: boolean }>({
        page: 0
    });
    const [orders, setOrders] = useState<ShippingOrderDTO>();
    const [searchString, setSearchString] = useState<string>("");
    const loadOnChange = useCallback(loadData, [searchState]);
    const [selectedOrder, setSelectedOrder] = useState<ShippingOrder>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [parcelCarrierList, setParceCarrierList] = useState<ParcelCarrierDTO[]>([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        getParcelCarriers();
    }, []);


    useEffect(() => {
        loadData();
    }, [searchState, loadOnChange]);


    useEffect(() => {
        setSearchState((prevState) => ({ ...prevState, showAll }));
    }, [showAll]);


    function getParcelCarriers() {
        axiosInstance
            .get<ParcelCarrierDTO[]>("/parcelcarrier")
            .then(response => {
                setParceCarrierList(response.data)
            })
    }

    function loadData() {
        axiosInstance
            .get<ShippingOrderDTO>("/deliverytracking", {params: searchState})
            .then((response) => {
                setOrders(response.data)
            });
    }

    function initShippingModal(order: ShippingOrder) {

        setSelectedOrder(order);
        setShowModal(true);
    }

    function search() {
        //we want always display the number, even if closed.
        setSearchState({page: 0, orderId: searchString, showAll: true});
    }

    function shippingSaveHandler() {
        axiosInstance
            .put(`/deliverytracking/${selectedOrder.id}`, {
                "carrierId": selectedOrder.carrierId,
                "trackingReferences": selectedOrder.trackingReferences.map(x => x.trackingReference)
            }).then(() => {
            closeModalHandler();
            loadData();
        })
    }

    function closeModalHandler() {
        setShowModal(false);
    }

    return <>
        {orders &&
            <div>
                <h2>{t("SHIPPING_OVERVIEW.TITLE")}</h2>
                <div className="row mt-3">
                    <div className="col-4">
                        <input
                            type="number"
                            className="form-control"
                            value={searchString}
                            placeholder={t("SHIPPING_OVERVIEW.ORDER_ID")}
                            onChange={(e) => setSearchString(e.target.value) }
                            onKeyDown={event => event.key === 'Enter' && search()}
                        />
                    </div>
                    <div className="col-4">
                        <button className="btn btn-primary" onClick={() => search()}>
                            {t("SHIPPING_OVERVIEW.SEARCH")}
                        </button>
                    </div>
                </div>
                <div className={"form-control-plaintext ms-1 cursor-pointer"} onClick={() => setShowAll(!showAll)}>
                    <input id={"chk-show-all"} type={"checkbox"} checked={showAll} onChange={(e) => setShowAll(e.target.checked)} />
                    <span> {t("SHIPPING_OVERVIEW.SHOW_ALL")}</span>
                </div>

                <ListPagination
                    currentPage={searchState.page}
                    totPages={orders?.totalPages}
                    pageClicked={(page: number) => setSearchState({...searchState, page})}
                />
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>{t("SHIPPING_OVERVIEW.ORDER_ID")}</th>
                        <th className="text-end">{t("SHIPPING_OVERVIEW.PRICE")}</th>
                        <th>{t("SHIPPING_OVERVIEW.PAYMENT_STATUS")}</th>
                        <th>{t("SHIPPING_OVERVIEW.HAS_TRACKING")}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        orders.orders.length === 0
                            ? <tr><td colSpan={5}>{t("SHIPPING_OVERVIEW.NO_RESULTS_FOUND")}</td></tr>
                            :   orders.orders.map((order) => {
                                    return (
                                        <tr key={order.id}>
                                            <td className="align-middle">{order.id}</td>
                                            <td className="align-middle text-end">
                                                <NumericFormat
                                                    value={order.priceGross}
                                                    displayType="text"
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    suffix="€"
                                                />
                                            </td>
                                            <td className="align-middle">{t('ENUMS.PAYMENT_STATUS.' + order.paymentStatus)}</td>
                                            <td className="align-middle"><input type={"checkbox"}
                                                                                checked={order.hasDeliveryTracking}
                                                                                disabled={true}/></td>
                                            <td className="text-end">
                                                <button className="btn btn-primary" onClick={() => initShippingModal(order)}>
                                                    {t(order.hasDeliveryTracking ? "SHIPPING_OVERVIEW.OPEN_SHIPPING_DETAILS" : "SHIPPING_OVERVIEW.MARK_AS_SHIPPED" )}
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                    }
                    </tbody>
                </table>
                <ListPagination
                    currentPage={searchState.page}
                    totPages={orders?.totalPages}
                    pageClicked={(page: number) => setSearchState({...searchState, page})}
                />
            </div>
        }
            <ShoppingOverviewContext.Provider value={{
                selectedOrder: selectedOrder,
                parcelCarrierList: parcelCarrierList,
            }}>
                <CustomModal open={showModal} backdropClick={closeModalHandler} modalClasses={"shopping-modal"}>
                    <ShippingOverviewModal closeHandler={closeModalHandler}
                                           saveHandler={shippingSaveHandler}/>

                </CustomModal>
            </ShoppingOverviewContext.Provider>


    </>;
}


