import {Moment} from 'moment';

export interface VoucherDTO {
    code?: string;
    id?: number;
    name?: string;
    type?: VoucherType | string;
    validFrom?: Moment | string;
    validTo?: Moment | string;
    itemType?: VoucherItemType | string;
    positionType?: PositionType;
    //PercentageVoucherDTO, FixedPriceVoucherDTO
    minimumOrderPrice?: number;
    minimumOrderValue?: number;
    voucherCategory?: VoucherCategory | string;
    // DiscountVoucherDTO
    valueToBuy?: number;
    valueToPay?: number;
    // PercentageVoucherDTO
    percentageReduction?: number;
    // FixedPriceVoucherDTO
    priceReduction?: number;
    //clientOnly
    useItemType?: boolean;
    useMinimumOrderPrice?: boolean;
    useMinimumOrderValue?: boolean;
}

export enum VoucherType {
    DISCOUNT_VOUCHER = 'DISCOUNT_VOUCHER',
    FIXED_PRICE_VOUCHER = 'FIXED_PRICE_VOUCHER',
    PERCENTAGE_VOUCHER = 'PERCENTAGE_VOUCHER'
}

export enum PositionType {
    ARTICLE = 'ARTICLE',
    MOUNTED_WHEEL = 'MOUNTED_WHEEL',
    RIM = 'RIM',
    TYRE = 'TYRE',
    VOUCHER = 'VOUCHER'
}

export enum VoucherItemType {
    ARTICLE = 'ARTICLE',
    MOUNTINGKIT = 'MOUNTINGKIT',
    RIM = 'RIM',
    TPMS = 'TPMS',
    TYRE = 'TYRE',
    VOUCHER = 'VOUCHER'
}

export enum VoucherCategory {
    CART = 'CART',
    ITEM = 'ITEM'
}
